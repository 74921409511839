
.homepage {
    background: url("/bg.jpeg");
    background-size: cover;
}
.homepage .grid-root {
    min-height: 100vh;
}

.homepage .title {
    font-weight: 700;
    font-family: "Source Sans Pro", "Roboto";
    color: white;
    text-shadow: 0px 0px 3px #222222;
}

.homepage .info-link {
    color: white;
    font-weight: 100;
    padding-left: 2px;
}

.homepage .info-link:hover {
    text-decoration: none;
    font-weight: bold;
    transition: .2s;
}

.homepage .body-button-container {
    margin-top: 72px;
}